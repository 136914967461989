export const queryFragmentBlocks = {
  blocks: {
    populate: {
      fields: [
        'i18n_string_en',
        'i18n_string_es',
        'i18n_string_ca',

        'i18n_richtext_en',
        'i18n_richtext_es',
        'i18n_richtext_ca',

        'view_option',
      ],

      // images
      images: {
        populate: true
      },

      // downloads
      downloads: {
        populate: {
          labels: true,
          file: true
        }
      },
      descriptions: true,

      // links
      ...queryFragmentLinks,

      // faqs
      faq_entries: {
        populate: {
          titles: true,
          items: {
            populate: {
              labels: true,
              descriptions: true
            }
          }
        }
      },

      // mux video
      mux_asset: true
    }
  }
}

export const queryFragmentFirstRichTextBlock = {
  on: {
    'blocks.text': {
      filters: {
        i18n_richtext_en: {
          $notNull: true
        }
      }
    }
  }
}
